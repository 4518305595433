import Elaiaorigin1 from "./elaiaorigin (1).png";
import Elaiaorigin2 from "./elaiaorigin (2).png";
import Elaiaorigin3 from "./elaiaorigin (3).png";
import Elaiaorigin4 from "./elaiaorigin (4).png";
import Elaiaorigin5 from "./elaiaorigin (5).png";
import Elaiaorigin6 from "./elaiaorigin (6).png";
import Elaiaorigin7 from "./elaiaorigin (7).png";
import Elaiaorigin8 from "./elaiaorigin (8).png";

const elaiaorigin = [
  Elaiaorigin1,
  Elaiaorigin2,
  Elaiaorigin3,
  Elaiaorigin4,
  Elaiaorigin5,
  Elaiaorigin6,
  Elaiaorigin7,
  Elaiaorigin8,
];
export default elaiaorigin;
