import Elaiaultimate1 from "./elaiaultimate (1).png";
import Elaiaultimate2 from "./elaiaultimate (2).png";
import Elaiaultimate3 from "./elaiaultimate (3).png";
import Elaiaultimate4 from "./elaiaultimate (4).png";
import Elaiaultimate5 from "./elaiaultimate (5).png";
import Elaiaultimate6 from "./elaiaultimate (6).png";
import Elaiaultimate7 from "./elaiaultimate (7).png";
import Elaiaultimate8 from "./elaiaultimate (8).png";

const elaiaultimate = [
  Elaiaultimate1,
  Elaiaultimate2,
  Elaiaultimate3,
  Elaiaultimate4,
  Elaiaultimate5,
  Elaiaultimate6,
  Elaiaultimate7,
  Elaiaultimate8,
];
export default elaiaultimate;
