import Fontis82 from "./fontis8 (1).webp";
import Fontis83 from "./fontis8 (2).webp";
import Fontis84 from "./fontis8 (3).webp";
import Fontis85 from "./fontis8 (4).webp";
import Fontis86 from "./fontis8 (5).webp";
import Fontis87 from "./fontis8 (6).webp";
import Fontis88 from "./fontis8 (7).webp";
import Fontis89 from "./fontis8 (8).webp";
import Fontis810 from "./fontis8 (9).webp";
import Fontis811 from "./fontis8 (10).webp";

const fontis8 = [
  Fontis82,
  Fontis83,
  Fontis84,
  Fontis85,
  Fontis86,
  Fontis87,
  Fontis88,
  Fontis89,
  Fontis810,
  Fontis811,
];

export default fontis8;
