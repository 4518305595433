import Fontis122 from "./fontis12 (1).webp";
import Fontis123 from "./fontis12 (2).webp";
import Fontis124 from "./fontis12 (3).webp";
import Fontis125 from "./fontis12 (4).webp";
import Fontis126 from "./fontis12 (5).webp";
import Fontis127 from "./fontis12 (6).webp";
import Fontis128 from "./fontis12 (7).webp";
import Fontis129 from "./fontis12 (8).webp";
import Fontis1210 from "./fontis12 (9).webp";
import Fontis1211 from "./fontis12 (10).webp";
import Fontis1212 from "./fontis12 (11).webp";
import Fontis1213 from "./fontis12 (12).webp";
import Fontis1214 from "./fontis12 (13).webp";
import Fontis1215 from "./fontis12 (14).webp";

const fontis12 = [
  Fontis122,
  Fontis123,
  Fontis124,
  Fontis125,
  Fontis126,
  Fontis127,
  Fontis128,
  Fontis129,
  Fontis1210,
  Fontis1211,
  Fontis1212,
  Fontis1213,
  Fontis1214,
  Fontis1215,
];

export default fontis12;
