import Fontis102 from "./fontis10 (1).webp";
import Fontis103 from "./fontis10 (2).webp";
import Fontis104 from "./fontis10 (3).webp";
import Fontis105 from "./fontis10 (4).webp";
import Fontis106 from "./fontis10 (5).webp";
import Fontis107 from "./fontis10 (6).webp";
import Fontis108 from "./fontis10 (7).webp";
import Fontis109 from "./fontis10 (8).webp";
import Fontis1010 from "./fontis10 (9).webp";
import Fontis1011 from "./fontis10 (10).webp";
import Fontis1012 from "./fontis10 (11).webp";
import Fontis1013 from "./fontis10 (12).webp";
import Fontis1014 from "./fontis10 (13).webp";
import Fontis1015 from "./fontis10 (14).webp";

const fontis10 = [
  Fontis102,
  Fontis103,
  Fontis104,
  Fontis105,
  Fontis106,
  Fontis107,
  Fontis108,
  Fontis109,
  Fontis1010,
  Fontis1011,
  Fontis1012,
  Fontis1013,
  Fontis1014,
  Fontis1015,
];

export default fontis10;
